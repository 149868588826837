//  必须的路由相关
function mainRouters() {
    return   {
        "title": "工作台",
        "path": "/home",
        "isActivate": true,
        "children": [
            {
                "title": "分析页",
                "path": "/Index/index",
                component: () => import(/* webpackChunkName: "about" */ '@/views/Index/index.vue'),
                "isActivate": true,
                "roleTyps": [0,1],
            }
        ]
    }
}

export default mainRouters;

//  基础数据相关
function baseRouters() {
    return {
        "title": "物流工具箱",
        "path": "/BaseManage",
        "children": [
            {
                "title": "保价管理",
                "path": "/BaseManage/Admin/ProtectivePriceManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/ProtectivePriceManage.vue')
            },
            {
                "title": "增值服务管理",
                "path": "/views/BaseManage/Admin/ValueAddedServices",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/ValueAddedServices.vue')
            },
            {
                "title": "货物种类管理",
                "path": "/BaseManage/Admin/CargoTypeManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/CargoTypeManage.vue')
            },
            {
                "title": "货物种类管理",
                "path": "/BaseManage/CargoTypeList",
                "isActivate": true,
                "roleTyps": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/CargoTypeList.vue')
            },
            {
                "title": "航空公司管理",
                "path": "/BaseManage/Admin/AirlineCompany",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/AirlineCompany.vue')
            },
            {
                "title": "全球各洲、国家/地区信息管理",
                "path": "/BaseManage/Admin/CountryManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/CountryManage.vue')
            },
            {
                "title": "国家所属省（直辖市）、州信息管理",
                "path": "/BaseManage/Admin/ProvinceManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/ProvinceManage.vue')
            },
            {
                "title": "省（直辖市）、州所属县（市）、区信息管理",
                "path": "/BaseManage/Admin/CityManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/CityManage.vue')
            },
            {
                "title": "全球机场航站楼管理",
                "path": "/BaseManage/Admin/AirportTerminalManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/AirportTerminalManage.vue')
            },
            {
                "title": "全球机场货运站管理",
                "path": "/BaseManage/Admin/CargoTerminalManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/CargoTerminalManage.vue')
            },
            {
                "title": "全球机场信息管理",
                "path": "/BaseManage/Admin/AirportInformationManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/AirportInformationManage.vue')
            },

            {
                "title": "HSCode海关数据",
                "path": "/BaseManage/Admin/",
                "isActivate": true,
                "roleTyps": [0],
                "children":[
                    {
                        "title": "基础税则",
                        "path": "/BaseManage/Admin/HSCodeBasicProvisions",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/HSCodeBasicProvisions.vue')
                    },
                    {
                        "title": "双反规则",
                        "path": "/BaseManage/Admin/HSCodeRule",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/HSCodeRule.vue')
                    },
                    {
                        "title": "特惠、协定",
                        "path": "/BaseManage/Admin/PreferentialAgreement",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/HSCodePreferentialAgreement.vue')
                    },
                ]
            },
            {
                "title": "飞机相关信息管理",
                "path": "/BaseManage/Admin/AircraftInformationManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/AircraftInformationManage.vue')
            },
            {
                "title": "飞机综合装载数据信息管理",
                "path": "/BaseManage/Admin/FreightInformationManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/FreightInformationManage.vue')
            },
            {
                "title": "飞机集装器材信息管理",
                "path": "/BaseManage/Admin/ContainerEquipmentManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/ContainerEquipmentManage.vue')
            },
            {
                "title": "车辆类型信息管理",
                "path": "/BaseManage/Admin/CarTypeManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/CarTypeManage.vue')
            },
            {
                "title": "包装材料类型管理",
                "path": "/BaseManage/Admin/PackagingTypeManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/PackagingTypeManage.vue')
            },
            {
                "title": "包装材料收费标准管理",
                "path": "/BaseManage/Admin/PackagingCostManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/BaseManage/Admin/PackagingCostManage.vue')
            }
        ]
    }
}

export default baseRouters;

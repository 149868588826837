<template>
  <div class="home">
    <!--顶部布局-->
    <view v-if="isLayoutTop">
      <a-layout>
        <a-layout-header class="header">
          <div class="logo" style="display: flex; align-items: center">
            <div v-if="projectScene == 2" style=" margin-right: 5px; width: 44px;height: 44px; overflow: hidden; display: flex; justify-items: center; align-items: center">
              <a-avatar :size="36" :src="logo" @click="onIndex"/>
            </div>
            <a-avatar v-else :size="44" style="margin-right: 5px" :src="logo" @click="onIndex"/>
            <div class="title" style="width: 120px;">携航CarryGlobal</div>
          </div>
          <div class="menu">
            <a-menu
                v-model:selectedKeys="selectedKeys"
                mode="horizontal"
                :open-keys="openKeys"
                :items="menuList"
                @click="onClick"
            ></a-menu>

            <a-menu v-if="false" v-model:selectedKeys="selectedKeys"
                    v-model:openKeys="openKeys"
                    mode="horizontal"
                    @click="onClick"
                    @openChange="onOpenChange"
            >
              <a-sub-menu v-for="item in menuList" :key="item.path">
                <template #title>{{item.title}}</template>
                <template v-for="subItem in item.children" :key="subItem.path">
                  <template v-if="subItem.isActivate">
                    <a-sub-menu v-if="subItem.children">
                      <template #title>{{subItem.title}}</template>
                      <template v-for="tmpItem in subItem.children" :key="tmpItem.path">
                        <a-menu-item v-if="tmpItem.isActivate" :key="tmpItem.path">{{tmpItem.title}}</a-menu-item>
                      </template>
                    </a-sub-menu>
                    <a-menu-item v-else :key="subItem.path">
                      {{subItem.title}}
                    </a-menu-item>
                  </template>
                </template>
              </a-sub-menu>
            </a-menu>
          </div>
          <div class="info">
            <a-space>
              <!--消息-->
              <a-popover v-if="currentRole != 0" trigger="hover" placement="bottomRight">
                <template #content>
                  <div>
                    <a-list item-layout="horizontal" :data-source="messageItems">
                      <template #renderItem="{ item }">
                        <a-list-item>
                          <div style="width: 280px;">
                            <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{item.title}}</div>
                            <div style="color: #666; font-size: 13px; margin-top: 3px;">{{item.content}}</div>
                          </div>
                        </a-list-item>
                      </template>
                      <template #header>
                        <a-flex justify="space-between" align="center">
                          <div>消息</div>
                          <a-button type="link">消息接收管理</a-button>
                        </a-flex>
                      </template>
                      <template #footer>
                        <a-button>查看更多</a-button>
                      </template>
                    </a-list>
                  </div>
                </template>
                <BellOutlined />
              </a-popover>
              <!--个人信息-->
              <a-popover trigger="hover" placement="bottomRight">
                <template #content>
                  <div style="width: 300px; padding: 10px;">
                    <a-flex align="center">
                      <a-avatar :src="userLogo"></a-avatar>
                      <div style="margin-left: 10px;">
                        <div>{{userName}}</div>
                        <div style="border-radius: 10px; height: 20px; padding: 0 5px; color: dodgerblue; line-height: 20px; border: 1px solid dodgerblue; font-size: 10px;">企业认证</div>
                      </div>
                    </a-flex>
                    <a-flex justify="space-around" style="margin-top: 10px;">
                      <div @click="onClickType(1)" style="cursor: pointer">企业信息</div>
                      <div @click="onClickType(3)" style="cursor: pointer">实名认证</div>
                      <div @click="onClickType(4)" style="cursor: pointer">账号安全</div>
                    </a-flex>
                  </div>
                  <div style="padding: 20px 10px; border-top: 1px solid #f0f0f0">
                    <ClusterOutlined />
                    企业组织
                    <RightOutlined />
                  </div>
                  <div style="padding: 20px 10px; border-top: 1px solid #f0f0f0">
                    <div>
                      <DollarOutlined />
                      收益与余额
                      <RightOutlined />
                    </div>
                    <div style="display: flex; margin-top: 10px;">
                      <div style="flex: 1">
                        <div>可用额度</div>
                        <div>¥1030.07</div>
                      </div>
                      <div style="flex: 1">
                        <div>钱包余额</div>
                        <div>¥1030.07</div>
                      </div>
                    </div>
                  </div>
                  <div style="width: 100%; border-top: 1px solid #f0f0f0; padding: 10px 0;">
                    <a-button style="width: 100%;" @click="onLogout">退出登录</a-button>
                  </div>
                </template>
                <div>
                  <span style="margin:0 5px">{{userName}}</span>
                  <a-avatar :src="userLogo">
                    <template #icon><UserOutlined /></template>
                  </a-avatar>
                </div>
              </a-popover>
            </a-space>
          </div>
        </a-layout-header>
        <a-layout-content class="content">
          <router-view/>
        </a-layout-content>
      </a-layout>
    </view>
    <!--左右布局-->
    <view v-else>
      <a-layout-sider>Sider</a-layout-sider>
      <a-layout>
        <a-layout-header></a-layout-header>
        <a-layout-content>Content</a-layout-content>
      </a-layout>
    </view>
    <PopupPasswordEdit/>
  </div>
</template>

<script>

import { UserOutlined, BellOutlined, ClusterOutlined, DollarOutlined, RightOutlined} from '@ant-design/icons-vue';
import {message} from "ant-design-vue";
// import routerJson from '@/assets/json/router.json'
import PopupPasswordEdit from '@/components/PopupPasswordEdit'

import mainRouters from '@/router/main'
import serviceRouters from '@/router/service'
import priceRouters from '@/router/price'
import orderRouters from '@/router/order'
import financeRouters from '@/router/finance'
import userRouters from '@/router/user'
import baseRouters from '@/router/base'
import organizationRouters from '@/router/organization'
import appletRouters from '@/router/applet'
import systemRouters from '@/router/system'
import officialWebsiteRouters from '@/router/officialWebsite'
import operationRouters from '@/router/operation'
import marketOperationRouters from '@/router/marketOperation'

import {sys_gt_service_settle_GetDataList} from "@/api/Business/sys_gt_service_settle";
import {sys_gt_citycounty_GetDataList} from "@/api/Areas/sys_gt_citycounty";
import {sys_users_GetUserData} from '@/api/User/sys_users'
import {sys_gt_googtypes_GetDataAllTreeList} from "@/api/Business/sys_gt_googtypes";
import {defaultAvatar} from '@/utils/default'

export default {
  name: 'HomeView',
  components:{
    UserOutlined,
    BellOutlined,
    ClusterOutlined,
    DollarOutlined,
    RightOutlined,
    PopupPasswordEdit,
  },
  data() {
    return {
      // logo:require('@/assets/logo.png'),
      logo:require('@/assets/logo.gif'),
      isLayoutTop:true,
      selectedKeys:[],
      openKeys:[],
      allMenuList:[], //  所有单路由菜单项
      menuList:[],    //  当前角色单路由菜单项
      userName:'',
      userLogo:'',
      isHaveService:false,  //  当前账户是否有服务商权限
      currentRole: 0,  //  当前登录角色为 0:表示为管理员 1:服务商
      projectScene: 0,  //  项目场景 0：开发环境 1：PC用户端 2：管理端
      messageItems:[]
    }
  },
  created() {
    this.selectedKeys = [this.$route.path]
  },
  mounted() {

    //  获取当前场景
    this.projectScene = this.$store.getters['projectScene']
    const userInfo = this.$store.getters['account/userInfo']
    if(userInfo == null){
      this.$router.push('/')
      return
    }
    this.isHaveService = userInfo && userInfo.u_usertype != null ? true : false

    if(userInfo.u_idcardname){
      this.userName = userInfo.u_idcardname
    }else if(userInfo.u_name){
      this.userName = userInfo.u_name
    }else {
      this.userName = userInfo.u_phone
    }
    this.userLogo = userInfo.u_faceurl
    if(this.userLogo == null || this.userLogo.length == 0){
      this.userLogo = defaultAvatar
    }
    message.success(`${this.userName}, 欢迎回来`)
    //  当前登录的用户角色 u_usertype = 66 为超级管理员
    //  更换当前存储的用户类型
    let roleType = localStorage.getItem('roleType')
    if(roleType) {
      this.currentRole = Number(roleType)
    }else {
      //  登录用户的服务商信息
      let serverInfo = userInfo.ServerInfo
      //  如果serverInfo 为null的话，则是个人用户
      if(this.projectScene == 0){
        this.currentRole =  userInfo.u_usertype == 66 ? 0 : 1
        if(serverInfo != null && serverInfo.sgsa_logo != null){
          this.logo = serverInfo.sgsa_logo
        }
      } else if (this.projectScene == 1){
        if(serverInfo != null && serverInfo.sgsa_logo != null){
          this.logo = serverInfo.sgsa_logo
        }
        this.currentRole =  1
      } else if (this.projectScene == 2){
        this.currentRole =  0
      }
    }
    this.loadingMenuData()
    this.loadUserInfoData()
    // this.loadAllCityData()
    this.loadServiceSettleInfo()
    this.loadGoodTypsCacheData()

    this.messageItems = [{
      title:'您的入驻申请【国内出港】已通过审核，请查看详情。',content:'2024-08-20'
    }]
  },
  methods:{
    loadingMenuData(){
      const mainItem = mainRouters()
      const serviceItem = serviceRouters()
      const priceItem = priceRouters()
      const orderItem = orderRouters()
      const financeItem = financeRouters()
      const userItem = userRouters()
      const baseItem = baseRouters()
      const organizationItem = organizationRouters()
      const appletItem = appletRouters()
      const systemItem = systemRouters()
      const officialWebsiteItem = officialWebsiteRouters()
      const operationItem = operationRouters()
      const marketOperationItem = marketOperationRouters()

      let tmpMenuList = [mainItem,serviceItem, priceItem, orderItem,operationItem, financeItem, userItem, organizationItem, baseItem, systemItem]
      //  原本这里是需要处理的
      if(this.currentRole == 0 || this.projectScene == 0){
        tmpMenuList.push(appletItem)
        tmpMenuList.push(officialWebsiteItem)
        tmpMenuList.push(marketOperationItem)
      }
      this.allMenuList = tmpMenuList
      this.updateMenuData()
    },
    //  根据登录用户的角色变化改变显示的菜单信息
    updateMenuData(){
      // const servertypes = this.$store.getters['servertypes']
      let tmpAllMenuList = JSON.parse(JSON.stringify(this.allMenuList))
      console.log('所有菜单:', tmpAllMenuList)
      let tmpMenuList = []
      tmpAllMenuList.forEach((element)=>{
        let tmpItem = this.recursiveMenuItem(element)
        if(tmpItem){
          // if(tmpItem.isService){
          //   //  服务业务判断
          //   if(tmpItem.children){
          //     let tmpSubItems = tmpItem.children.filter(subItem=>{
          //       if(subItem.isActivate && subItem.serviceType && servertypes){
          //         return servertypes.includes(subItem.serviceType)
          //       }else {
          //         return true
          //       }
          //     })
          //     if(tmpSubItems.length){
          //       tmpItem.children = tmpSubItems
          //     }
          //   }
          // }
          tmpMenuList.push(tmpItem)
        }
      })

      console.log('处理之后的菜单:', tmpMenuList)
      this.menuList = tmpMenuList
    },
    //  递归查询某个菜单是否可用
    recursiveMenuItem(item){
      if(item.roleTyps){
        //  如果有角色判断，则优先判断当前菜单角色是否可用,如果不可用，直接返回nil，如果可用，则继续判断是否有子菜单
        if(!item.roleTyps.includes(this.currentRole)){
          return null
        }
      }
      if(item.children){
        let tmpChildren = []
        item.children.forEach((element)=>{
          let tmpItem = this.recursiveMenuItem(element)
          if(tmpItem && tmpItem.isActivate){
            tmpChildren.push(tmpItem)
          }
        })
        if(tmpChildren.length > 0){
          item.children = tmpChildren
        }else {
          item.children = null
        }
      }
      item.key = item.path
      item.label = item.title
      return item
    },

    onChangeRole(value){
      this.currentRole = value == 0 ? 1 : 0
      if(value == 0){
        this.$router.push('/Index/index')
      } else {
        this.$router.push('/ServiceManage/Admin/ServiceProvider')
      }
      //  更换当前存储的用户类型
      localStorage.setItem('roleType', this.currentRole)
      this.updateMenuData()
    },
    onClick(event){
      let tmpPath = event.key
      if(tmpPath[0] != '/') {
        tmpPath = `/${tmpPath}`
      }
      this.selectedKeys = event.keyPath
      this.$router.push(tmpPath)
    },
    onOpenChange(openKeys){
      console.log('openKeys', openKeys)
      // const lastOpenKey = openKeys.find(key=> this.openKeys.indexOf(key) === -1)
      // console.log('lastOpenKey', lastOpenKey)
    },
    onLogout(){
      this.$router.push('/')
    },
    onIndex(){
      // if(this.currentRole == 0){
      //   this.$router.push('/Index/index')
      // } else {
      //   this.$router.push('/ServiceManage/Admin/ServiceProvider')
      // }
      this.$router.push('/Index/index')
    },
    //  下载部分备用数据
    //  获取所有的城市
    loadAllCityData(){
      let parmeters = {SortField:"id", SortType:"desc"}
      this.$post(sys_gt_citycounty_GetDataList, parmeters).then(()=>{
        // console.log('城市地址:', response)
        // localStorage.setItem('all_cityList', JSON.stringify(response.Data))
      })
    },
    //  获取货物种类数据
    loadGoodTypsCacheData(){
      let queryInfo = {
        PageIndex:1,
        PageRows:1000,
        SortField: "id",
        SortType: "desc",
        checkstatus:'1'
      }
      this.$post(sys_gt_googtypes_GetDataAllTreeList,queryInfo).then((response)=>{
        //  处理数据
        if(response != null && response.Success){
          let tmpItems = response.Data
          let tmpOptions = []
          let tmpAllOptions = []
          tmpItems.forEach((element)=>{
            let OneBTypes = element.OneBType.split(',')
            if(OneBTypes.includes(`${this.serviceType}`)) {
              let subOptions = []
              element.TwoGoodsTreeList.forEach((subElement)=>{
                let threeSubOptions = []
                subElement.ThreeGoodsTreeList.forEach((threeElement)=>{
                  let tmpThreeTypeId = `${threeElement.OneTypeId}_${threeElement.TwoTypeId}_${threeElement.ThreeTypeId}`
                  let tmpThreeTypeName = `${threeElement.OneTypeName}_${threeElement.TwoTypeName}_${threeElement.ThreeTypeName}`
                  threeSubOptions.push({value:tmpThreeTypeId, label:threeElement.ThreeTypeName, name: tmpThreeTypeName})
                })
                let tmpTwoTypeId = `${subElement.OneTypeId}_${subElement.TwoTypeId}`
                let tmpTwoTypeName = `${subElement.OneTypeName}_${subElement.TwoTypeName}`
                subOptions.push({value:tmpTwoTypeId, label:subElement.TwoTypeName,children: threeSubOptions, name:tmpTwoTypeName})
                tmpAllOptions = tmpAllOptions.concat(threeSubOptions)
              })
              tmpOptions.push({value:element.OneTypeId, label:element.OneTypeName, children: subOptions, name: element.OneTypeName})
              tmpAllOptions = tmpAllOptions.concat(subOptions)
            }
          })
          tmpAllOptions = tmpAllOptions.concat(tmpOptions)
          //  数据需要保存到本地
          if(tmpAllOptions.length > 0 && tmpOptions.length > 0){
            localStorage.setItem('local_AllOptions', JSON.stringify(tmpAllOptions))
            localStorage.setItem('local_TreeOptions', JSON.stringify(tmpOptions))
          }
        }
      })
    },
    //  获取用户数据
    loadUserInfoData(){
      let userInfo = this.$store.getters['account/userInfo']
      let parmeters = {id: userInfo.id}
      let that = this
      this.$post(sys_users_GetUserData, parmeters).then((response)=>{
        //  数据可能有修改，此处需要更新UI
        if(response.Data){
          const updateUserInfo = response.Data
          let tmpUser = updateUserInfo.User
          if(tmpUser.u_idcardname){
            that.userName = tmpUser.u_idcardname
          }else if(tmpUser.u_name){
            that.userName = tmpUser.u_name
          }else {
            that.userName = tmpUser.u_phone
          }
          that.userLogo = tmpUser.u_faceurl
          if(that.userLogo == null || that.userLogo.length == 0){
            that.userLogo = defaultAvatar
          }
          userInfo.u_name = tmpUser.u_name
          userInfo.u_phone = tmpUser.u_phone
          userInfo.u_faceurl = tmpUser.u_faceurl
          userInfo.u_idcardname = tmpUser.u_idcardname
          that.$store.commit('account/saveUserInfo', userInfo)
        }
      })
    },
    //  获取用户入驻的业务模式
    loadServiceSettleInfo(){
      const userInfo = this.$store.getters['account/userInfo']
      const serverId = userInfo.id
      let queryInfo = {
        PageIndex:1,
        PageRows:10,
      }
      let search = {'Condition1':'sgss_uid','Keyword1':serverId,'Condition2':'sgss_state','Keyword2':1}
      queryInfo.Search = search
      let that = this
      this.$post(sys_gt_service_settle_GetDataList,queryInfo).then((response)=>{
        //  处理数据
        if(response.Success){
          const tmpJsonItems = response.Data
          let tmpServertypes = []
          tmpJsonItems.forEach(element=>{
            if(!tmpServertypes.includes(element.sgss_servertype)){
              tmpServertypes.push(element.sgss_servertype)
            }
          })
          that.$store.commit('setServertypes', tmpServertypes)
          that.updateMenuData()
        }else {
          message.error(response.Msg)
        }
      })
    },
    //  点击类型
    onClickType(type){
      this.$router.push('/ServiceManage/ServiceDetailInfo?type=' + type)
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  background-color: white;
  display: flex;
  width: 100%;
  padding: 0;
  .logo {
    margin: 0 0 0 20px;
  }
  .menu{
    flex: 1;
    overflow: scroll;
  }
  .info {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
  }
}
.content{
  background-color: #f0f0f0;
  padding: 30px;
}

:deep(.ant-list-item){
  padding: 10px 0 !important;
}

</style>

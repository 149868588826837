//  财务管理数据相关
function financeRouters() {
    return {
        "title": "财务管理",
        "path": "/FinanceManage/index",
        // component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/index.vue'),
        "children": [
            {
                label:'资金中心',
                key:'menu1',
                title: "资金中心",
                path:'/FinanceManage/FundingCenter',
                isActivate: true,
                // type:'group',
                userType:[1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/index.vue'),
                children:[
                    {
                        title:'首页',
                        label: '首页',
                        key: '/FinanceManage/FundingCenter',
                        path:'/FinanceManage/FundingCenter',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/FundingCenter.vue')
                    },
                    {
                        title:'资金账户',
                        label: '资金账户',
                        key: '/FinanceManage/FundAccount',
                        path:'/FinanceManage/FundAccount',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/FundAccount.vue')
                    },
                    {
                        label:'交易明细',
                        key:'/FinanceManage/TransactionDetails',
                        title:'交易明细',
                        path:'/FinanceManage/TransactionDetails',
                        userType:[3],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/TransactionDetails.vue')
                    },
                    {
                        label:'资金流水',
                        key:'/FinanceManage/CapitalFlow',
                        title:'资金流水',
                        path:'/FinanceManage/CapitalFlow',
                        userType:[3],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/CapitalFlow.vue')
                    },
                    {
                        label:'账单',
                        key:'/FinanceManage/FundBill',
                        title:'账单',
                        path:'/FinanceManage/FundBill',
                        userType:[3],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/FundBill.vue')
                    },
                ],
            },
            {
                label:'资金相关',
                title:'资金相关',
                key:'menu2',
                type:'group',
                path:'/FinanceManage/TransactionDetails',
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/index.vue'),
                userType:[1,2],
                children:[
                    // {
                    //     label:'交易明细',
                    //     key:'/FinanceManage/TransactionDetails',
                    //     title:'交易明细',
                    //     path:'/FinanceManage/TransactionDetails',
                    //     component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/TransactionDetails.vue')
                    // },
                    {
                        label: '提现管理',
                        key: "/FinanceManage/WithdrawalList",
                        "title": "提现管理",
                        "path": "/FinanceManage/WithdrawalList",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/WithdrawalList.vue')
                    },
                    // {
                    //     label:'资金流水',
                    //     key:'/FinanceManage/CapitalFlow',
                    //     title:'资金流水',
                    //     path:'/FinanceManage/CapitalFlow',
                    //     component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/CapitalFlow.vue')
                    // },
                    // {
                    //     label:'账单',
                    //     key:'/FinanceManage/TransactionDetails',
                    //     path:'/FinanceManage/TransactionDetails',
                    // },
                    {
                        label:'资金操作记录',
                        key:'/FinanceManage/Admin/FundOperation',
                        "title": "资金操作记录",
                        "path": "/FinanceManage/Admin/FundOperation",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/FundOperation.vue')
                    },
                ],
            },
            {
                label:'平台资金管理',
                key:'menu3',
                type:'group',
                path:'/FinanceManage/PlatformFunds',
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/index.vue'),
                userType:[0],
                children:[
                    {
                        label: "交易明细管理",
                        key: "/FinanceManage/Admin/TransactionDetails",
                        "title": "交易明细管理",
                        "path": "/FinanceManage/Admin/TransactionDetails",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/TransactionDetails.vue')
                    },
                    {
                        label:'提现申请管理',
                        key:'/FinanceManage/Admin/WithdrawalApplication',
                        "title": "提现申请管理",
                        "path": "/FinanceManage/Admin/WithdrawalApplication",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/WithdrawalApplication.vue')
                    },
                    {
                        label:'平台收益管理',
                        key:'/FinanceManage/Admin/PlatformRevenue',
                        "title": "平台收益管理",
                        "path": "/FinanceManage/Admin/PlatformRevenue",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/PlatformRevenue.vue')
                    },
                    {
                        label:'资金流水',
                        key:'/FinanceManage/Admin/CapitalFlow',
                        "title": "资金流水",
                        "path": "/FinanceManage/Admin/CapitalFlow",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/CapitalFlow.vue')
                    },
                    {
                        label:'账单',
                        "title": "账单",
                        key:'/FinanceManage/Admin/Bill',
                        path:'/FinanceManage/Admin/Bill',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/Bill.vue')
                    },
                    {
                        label:'资金操作记录',
                        key:'/FinanceManage/Admin/FundOperation',
                        path:'/FinanceManage/Admin/FundOperation',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/FundOperation.vue')
                    },
                ],
            },
            {
                "title": "提现管理",
                "path": "/FinanceManage/WithdrawalList",
                "roleTyps": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/WithdrawalList.vue')
            },
            {
                "title": "财务管理",
                "path": "/FinanceManage/Admin/index",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/index.vue')
            },
            {
                "title": "交易明细",
                "path": "/FinanceManage/Admin/TransactionDetails",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/TransactionDetails.vue')
            },
            {
                "title": "资金流水",
                "path": "/FinanceManage/Admin/CapitalFlow",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/CapitalFlow.vue')
            },
            {
                "title": "提现管理",
                "path": "/FinanceManage/Admin/WithdrawalApplication",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/WithdrawalApplication.vue')
            },
            {
                "title": "资金操作",
                "path": "/FinanceManage/Admin/FundOperation",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/FundOperation.vue')
            },
            {
                "title": "平台收益",
                "path": "/FinanceManage/Admin/PlatformRevenue",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/FinancePages/PlatformRevenue.vue')
            },
            {
                "title": "发票管理",
                "path": "/FinanceManage/InvoicePage",
                "isActivate": true,
                "roleTyps": [0,1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/InvoicePage.vue'),
            },
            {
                "title": "发票管理",
                "path": "/FinanceManage/InvoiceManage",
                // "isActivate": true,
                "roleTyps": [1],
                "children":[
                    {
                        "title": "入驻费用开票管理",
                        "path": "/FinanceManage/InvoiceSettledList",
                        "isActivate": true,
                        "roleTyps": [1],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/InvoiceSettledList.vue')
                    },
                    {
                        "title": "抬头管理",
                        "path": "/FinanceManage/InvoiceHeaderManage",
                        "isActivate": true,
                        "roleTyps": [1],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/InvoiceHeaderManage.vue')
                    },
                    {
                        "title": "提供发票",
                        "path": "/FinanceManage/InvoiceList",
                        "isActivate": true,
                        "roleTyps": [1],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/InvoiceList.vue')
                    },
                ]
            },
            {
                "title": "用户申请开票管理",
                "path": "/FinanceManage/Admin",
                // "isActivate": true,
                "roleTyps": [0],
                "children":[
                    {
                        "title": "订单开票管理",
                        "path": "/FinanceManage/Admin/InvoicePages/OrderInvoice",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/InvoicePages/OrderInvoice.vue')
                    },
                    {
                        "title": "入驻服务费开票管理",
                        "path": "/FinanceManage/Admin/InvoicePages/SettledInvoice",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/InvoicePages/SettledInvoice.vue')
                    },
                ]
            },
            {
                "title": "服务商提供发票管理",
                "path": "/FinanceManage/Admin/InvoicePages/ServiceProvidedInvoice",
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/InvoicePages/ServiceProvidedInvoice.vue')
            },
            {
                title:'保证金核算',
                path:'/FinanceManage/Admin/EarnestMoney',
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/FinanceManage/Admin/EarnestMoney.vue')
            },
        ]
    }
}

export default financeRouters;

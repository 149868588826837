//  官网管理
function officialWebsiteRouters() {
    return {
        "title": "官网管理",
        "path": "/officialWebsiteManage",
        "roleTyps": [0],
        "children": [
        ]
    }
}

export default officialWebsiteRouters;

//  市场运营
function marketOperationRouters() {
    return {
        "title": "运营管理",
        "path": "/MarketOperationManage",
        "roleTyps": [0],
        "children": [
            {
                "title": "推荐管理",
                "path": "/MarketOperationManage/RecommendManage",
                "isActivate": true,
                "roleTyps": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/MarketOperationManage/RecommendManage.vue')
            },
            {
                "title": "自建网络",
                "path": "/SelfBuiltNetworkManage/Admin/SupplierList",
                "isActivate": true,
                "children":[
                    {
                        "title": "供应商管理",
                        "path": "/SelfBuiltNetworkManage/Admin/SupplierList",
                        "isActivate": true,
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SelfBuiltNetworkManage/Admin/SupplierList.vue')
                    },
                    {
                        "title": "报价管理",
                        "path": "/SelfBuiltNetworkManage/QuotationList",
                        "isActivate": true,
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SelfBuiltNetworkManage/QuotationList.vue')
                    },
                    {
                        "title": "新增报价",
                        "path": "/SelfBuiltNetworkManage/QuotationFrom",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SelfBuiltNetworkManage/QuotationFrom.vue')
                    },
                    {
                        "title": "供应商订单管理",
                        "path": "/SelfBuiltNetworkManage/Admin/OrderList",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SelfBuiltNetworkManage/Admin/OrderList.vue')
                    },
                    {
                        "title": "供应商收费管理",
                        "path": "/SelfBuiltNetworkManage/Admin/ChargeList",
                        "isActivate": true,
                        "roleTyps": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/SelfBuiltNetworkManage/Admin/ChargeList.vue')
                    },
                ]
            },
        ],
    }
}

export default marketOperationRouters;
